import React from 'react';
import SearchResults from '../../components/search/search-results';
import SearchBySort from '../../components/search/search-sortby';
import SearchFilters from '../../components/search/search-filters';
import { NvContainerConstrained } from '../../components/core/layout/containers.styles';
import { SearchContainer, SearchResultsContainer } from './search.styles';
import searchResultDefault from '../../assets/data/dummy-search-results.json';
import { useLocation } from 'react-router-dom';
import { SearchSanitiser } from '../../utils/formatting';

type SearchQueryParams = {
    sort: string;
    category: string;
};

const getQueryParams = (searchParams: URLSearchParams): SearchQueryParams => {
    return { sort: searchParams.get('sort') || 'Relevance', category: searchParams.get('category') || 'all' };
};

const Search = () => {
    const query = getQueryParams(new URLSearchParams(useLocation().search));
    const results = searchResultDefault;
    return (
        <NvContainerConstrained>
            <SearchContainer>
                <SearchResultsContainer>
                    <SearchFilters />
                    <SearchBySort
                        foundResultLength={results.length.toString()}
                        sort={SearchSanitiser(query.sort)}
                        category={SearchSanitiser(query.category)}
                    />
                    <SearchResults previewList={results} />
                </SearchResultsContainer>
            </SearchContainer>
        </NvContainerConstrained>
    );
};

export default Search;
