import React, { FC, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { DesktopHeader, MobileHeader } from './header';
import { Footer } from './footer';
import { useMediaQuery } from 'react-responsive';
import headerLinks from '../../../assets/data/header.json';
import Discount from './discount';

export const withLayout = <P extends Record<string, unknown>>(
    ContentComponent: FC<P>,
    layout: {
        title: string;
        metaDescription: string;
    } = { title: 'New Vision', metaDescription: 'New Vision' },
): FC<P> => {
    const { title, metaDescription } = layout;

    const WithLayout = (props: P) => {
        const isTabletOrMobile = useMediaQuery({ query: '(max-width: 860px)' });
        const isTabletOrMobileDevice = useMediaQuery({
            query: '(max-device-width: 860px)',
        });
        const isMobile = isTabletOrMobile && isTabletOrMobileDevice;

        const isDesktop = useMediaQuery({
            query: '(min-device-width: 861px)',
        });

        const siteDiscount = true;
        const helmetTitle = `${title} | New Vision`;
        const helmetMetaDesc = `${metaDescription} | New Vision Media UK`;

        return (
            <Fragment>
                <header>
                    <Helmet>
                        <title>{helmetTitle}</title>
                        <meta name="description" content={helmetMetaDesc} />
                    </Helmet>
                    {siteDiscount && <Discount />}
                    {isDesktop && <DesktopHeader headerLinks={headerLinks} />}
                    {isMobile && <MobileHeader headerLinks={headerLinks} />}
                </header>
                <main id="content">
                    <ContentComponent {...props} />
                </main>
                {Footer && <Footer />}
            </Fragment>
        );
    };

    return WithLayout;
};
