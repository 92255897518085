import styled from 'styled-components';
import colours from '../core/config/colours.json';
import dimensions from '../core/config/dimensions.json';

export const ProductCardContainer = styled.section`
    color: ${colours.black};
    background-color: ${colours.white};
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.15);
    max-width: 370px;
    max-height: 272px;
    border: 1px solid ${colours.whiteDark};
    border-radius: 4px;
    padding: 1%;
    margin: 1%;
    flex: 1 27%;
    transition: 0.15s ease-in-out;
    @media (max-width: 1000px) {
        flex: 1 40%;
        margin-bottom: 2%;
        :first-child,
        :nth-child(1n) {
            margin-left: 0;
        }
        :nth-child(2n) {
            margin-right: 0;
        }
    }
    @media (max-width: 340px) {
        flex: 1 100%;
        margin-bottom: 2%;
        :first-child,
        :nth-child(1n) {
            margin: 2% 0 0 0;
        }
    }
    @media (min-width: 1000px) {
        :first-child,
        :nth-child(3n + 1) {
            margin-left: 0;
        }
        :nth-child(3n) {
            margin-right: 0;
        }
    }

    :hover {
        box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.25);
    }
`;

export const Thumbnail = styled.img`
    border-radius: 4px;
    max-width: 100%;
    @media (max-width: ${dimensions.mobileMax}) {
        margin: 0 auto;
    }
`;

export const Category = styled.p`
    font-size: calc(0.6rem + 0.1vw);
    font-family: 'Inter', sans-serif;
    margin: 0;
    color: ${colours.blue};
`;

export const Price = styled.p`
    font-size: calc(0.55rem + 0.2vw);
    font-family: 'Inter', sans-serif;
    margin: 0;
    white-space: nowrap;
`;

export const Discount = styled.span`
    text-decoration: line-through;
    text-decoration-color: red;
    color: red;
`;

export const InformationContainer = styled.div`
    text-align: start;
    padding: 2%;
    margin-top: auto;
    width: 100%;
    max-width: 350px;
    height: 50px;
    @media (max-width: ${dimensions.mobileMax}) {
        text-align: center;
        padding: 8px 0 4px 0;
    }
`;

export const Title = styled.h1`
    font-size: calc(0.75rem + 0.75vw);
    font-family: 'NuckleBold', sans-serif;
    font-weight: 700;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
