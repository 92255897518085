import React from 'react';
import FAQ from '../../assets/data/faqs.json';
import { MiddleAlign, NvContainer, NvContainerConstrained } from '../../components/core/layout/containers.styles';
import { BeigeStrip } from '../../components/core/layout/strips.styles';
import { HeroText } from '../../components/core/typography.styles';

const HelpLandingPage = () => {
    return (
        <NvContainer>
            <BeigeStrip>
                <MiddleAlign>
                    <HeroText>Need some help?</HeroText>
                </MiddleAlign>
            </BeigeStrip>
            <NvContainerConstrained>
                <section id="faqs">
                    <h1>FAQs</h1>
                    {FAQ.map((faqItem) => (
                        <>
                            <h2>{faqItem.trigger}</h2>
                            <p>{faqItem.description}</p>
                        </>
                    ))}
                </section>
                <section id="contact-us">
                    <h1>Contact us</h1>
                    <p>
                        Drop us an email over at{' '}
                        <a href="mailto:hello@newvisionmedia.store" style={{ fontWeight: 700 }}>
                            hello@newvisionmedia.store
                        </a>
                    </p>
                </section>
            </NvContainerConstrained>
        </NvContainer>
    );
};

export default HelpLandingPage;
