import React from 'react';
import { NvContainerConstrained } from '../../components/core/layout/containers.styles';
import { WhiteLayeredCTABtn } from '../../components/core/button/buttons.styles';
import { BeigeStripeWide } from '../../components/core/layout/strips.styles';

const ErrorPage = () => {
    return (
        <BeigeStripeWide>
            <NvContainerConstrained style={{ textAlign: 'center', color: 'white' }}>
                <h1>Woops!</h1>
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '32px' }}>
                    <WhiteLayeredCTABtn href="/">Back To Home</WhiteLayeredCTABtn>
                </div>
            </NvContainerConstrained>
        </BeigeStripeWide>
    );
};

export default ErrorPage;
