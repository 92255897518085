import React from 'react';
import Routes from './routes';
import { withAppContext } from './utils/app-context';

const App = () => {
    return <Routes />;
};

const AppWithContext = withAppContext(App, {});

export default AppWithContext;
