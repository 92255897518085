import { createGlobalStyle } from 'styled-components';
import NuckleRegular from './nuckle/Nuckle-Regular.ttf';
import NuckleBold from './nuckle/Nuckle-Bold.ttf';

export default createGlobalStyle`
    @font-face {
        font-family: 'Inter';
        src: url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap') format('tff');
    }
    @font-face {
        font-family: 'Raleway';
        src: url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800;900&display=swap') format('tff');
    }
    @font-face {
        font-family: 'Nuckle';
        src: url(${NuckleRegular}) format('truetype');
    }
      @font-face {
        font-family: 'NuckleBold';
        src: url(${NuckleBold}) format('truetype');
    }
    body{
        font-family:'Raleway', sans-serif;
        font-weight: 500
}
`;
