import React from 'react';
import { MiddleAlign, NvContainerConstrained } from '../../components/core/layout/containers.styles';
import { MintStripeWide } from '../../components/core/layout/strips.styles';
import { HeroText } from '../../components/core/typography.styles';
export const LoggedOut = () => {
    return (
        <NvContainerConstrained>
            <MintStripeWide>
                <MiddleAlign>
                    <HeroText>Youve been logged out!</HeroText>
                </MiddleAlign>
            </MintStripeWide>
        </NvContainerConstrained>
    );
};

export default LoggedOut;
