import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import HamburgerMenu from 'react-hamburger-menu';
import Headroom from 'react-headroom';
import { useCart } from 'react-use-cart';
import { LogInBtn, LogOutBtn } from '../button/account-buttons';
import { NvContainerFixedWide } from './containers.styles';
import {
    AccountAreaBtn,
    Cart,
    CartMenu,
    FloatLeft,
    HamburgerMenuPadding,
    Header,
    HeaderContainer,
    HeaderLinkA,
    HeaderLinkAMobile,
    Logo,
    MobileHeaderContainer,
    MobileNavList,
    MobileNavSection,
    MobileUL,
    SubHeaderLinkAMobile,
    SubLinkMobile,
    UL,
    ULLI,
} from './header.styles';

export const DesktopHeader = (props: HeaderPropsAsClass) => {
    const { headerLinks } = props;
    const { isAuthenticated } = useAuth0();
    const { items } = useCart();

    return (
        <Headroom
            style={{
                boxShadow: '1px 1px 1px rgba(0,0,0,0.025)',
            }}
        >
            <Header>
                <HeaderContainer>
                    <FloatLeft>
                        <Logo href={'/'}>NEW VISION</Logo>
                    </FloatLeft>
                    <nav>
                        <UL>
                            {items.length > 0 && (
                                <CartMenu href="/cart">
                                    <Cart src="https://img.icons8.com/pastel-glyph/64/000000/shopping-cart--v2.png" />
                                </CartMenu>
                            )}
                            {headerLinks.map((link) => (
                                <ULLI key={link.displayText}>
                                    <HeaderLinkA href={link.url}>{link.displayText}</HeaderLinkA>
                                </ULLI>
                            ))}
                            {isAuthenticated && <AccountAreaBtn href={'/my-account'}>My Account</AccountAreaBtn>}
                            {!isAuthenticated && <LogInBtn />}
                        </UL>
                    </nav>
                </HeaderContainer>
            </Header>
        </Headroom>
    );
};

export const MobileHeader = (props: HeaderPropsAsClass) => {
    const [showNav, setShowNav] = useState(false);
    const { headerLinks } = props;
    const { items } = useCart();
    const { isAuthenticated } = useAuth0();

    return (
        <Headroom
            style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
            onUnfix={() => setShowNav(false)}
        >
            <MobileHeaderContainer>
                <Logo style={{ padding: `3px 0 0 10px` }} href="/">
                    NEW VISION
                </Logo>
                <HamburgerMenuPadding>
                    {items.length > 0 && (
                        <CartMenu href="/cart">
                            <Cart
                                width={24}
                                height={24}
                                src="https://img.icons8.com/pastel-glyph/64/000000/shopping-cart--v2.png"
                            />
                        </CartMenu>
                    )}
                    <HamburgerMenu
                        isOpen={showNav}
                        height={24}
                        width={28}
                        menuClicked={() => setShowNav(!showNav)}
                        color="black"
                        animationDuration={0.5}
                    />
                </HamburgerMenuPadding>
            </MobileHeaderContainer>
            {showNav && (
                <MobileNavList>
                    <NvContainerFixedWide>
                        {isAuthenticated && (
                            <AccountAreaBtn style={{ width: '100%', marginBottom: '8px' }} href={'/my-account'}>
                                My Account
                            </AccountAreaBtn>
                        )}
                        {isAuthenticated && <LogOutBtn fullWidth={true} />}
                        {!isAuthenticated && <LogInBtn fullWidth={true} />}
                    </NvContainerFixedWide>
                    <MobileUL>
                        {headerLinks.map((headerlink) => (
                            <MobileNavSection key={headerlink.displayText}>
                                <HeaderLinkAMobile href={headerlink.url}>{headerlink.displayText}</HeaderLinkAMobile>
                                <>
                                    {headerlink.subHeaders &&
                                        headerlink.subHeaders.map((subLinks) => (
                                            <SubLinkMobile key={subLinks.displayText}>
                                                <SubHeaderLinkAMobile href={subLinks.url}>
                                                    {subLinks.displayText}
                                                </SubHeaderLinkAMobile>
                                            </SubLinkMobile>
                                        ))}
                                </>
                            </MobileNavSection>
                        ))}
                    </MobileUL>
                </MobileNavList>
            )}
        </Headroom>
    );
};

interface HeaderPropsAsClass {
    headerLinks: Array<HeaderLink>;
}

interface HeaderLink {
    displayText: string;
    url: string;
    isActive?: boolean;
    isDivider?: boolean;
    subHeaders?: HeaderLink[];
}
