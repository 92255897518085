import React from 'react';
import styled from 'styled-components';
import colours from '../../components/core/config/colours.json';

interface SortByProps {
    foundResultLength: string;
    sort: string;
    category: string;
}

const SearchResultsTop = styled.div`
    font-weight: 400;
    display: inline-flex;
    width: 100%;
`;

const SearchResultsFound = styled.div`
    width: 50%;
    color: ${colours.black};
    span {
        color: ${colours.blue};
        font-weight: 600;
    }
`;

const SearchResultsSort = styled.div`
    width: 50%;
    display: inherit;
    justify-content: flex-end;

    color: ${colours.black};
    span {
        color: ${colours.blue};
        font-weight: 600;
    }
`;

const SearchBySort = (props: SortByProps) => {
    const currentSort = props.sort;
    const currentCategory = props.category;

    return (
        <SearchResultsTop>
            <SearchResultsFound>
                Found <span>{props.foundResultLength}</span> items in <span>{currentCategory}</span>
            </SearchResultsFound>
            <SearchResultsSort>
                Sorted by&nbsp;<span>{currentSort}</span>
            </SearchResultsSort>
        </SearchResultsTop>
    );
};

export default SearchBySort;
