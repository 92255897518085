import styled from 'styled-components';
import dimensions from '../../components/core/config/dimensions.json';
export const SearchContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-top: ${dimensions.baseUnit};
`;

export const SearchResultsContainer = styled.div`
    width: 100%;
    position: relative;
    display: block;
    overflow: visible;
    @media (max-width: ${dimensions.mobileMax}) {
        width: 100%;
    }
`;
