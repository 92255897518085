import React from 'react';
import { MiddleAlign, NvContainer } from '../../components/core/layout/containers.styles';
import { MintStripeWide } from '../../components/core/layout/strips.styles';
import { HeroText } from '../../components/core/typography.styles';

const FaithStatement = () => {
    return (
        <NvContainer>
            <MintStripeWide>
                <MiddleAlign>
                    <HeroText>Faith Statement</HeroText>
                </MiddleAlign>
            </MintStripeWide>
        </NvContainer>
    );
};

export default FaithStatement;
