import React from 'react';
import { Link } from 'react-router-dom';
import {
    Category,
    Discount,
    InformationContainer,
    Price,
    ProductCardContainer,
    Thumbnail,
    Title,
} from './product-card.styles';
import { CatalogueSearchRepresentation } from '../../service/product-services';

const ProductCatalogueCard = (props: CatalogueSearchRepresentation) => {
    const { title, thumbnail, fullPackUrl, category, price, discounted } = props;

    const parsedPrice = price === 'free' ? 'Free' : `£${price}`;

    return (
        <ProductCardContainer>
            <Link to={fullPackUrl}>
                <Thumbnail src={thumbnail} />
                <InformationContainer>
                    <Category>{category}</Category>
                    <Title>{title}</Title>
                    {discounted && (
                        <Price>
                            {`£${discounted}`} <Discount>{parsedPrice}</Discount>
                        </Price>
                    )}
                    {!discounted && <Price>{parsedPrice}</Price>}
                </InformationContainer>
            </Link>
        </ProductCardContainer>
    );
};

export default ProductCatalogueCard;
