import styled from 'styled-components';
import colours from '../../components/core/config/colours.json';
import dimensions from '../../components/core/config/dimensions.json';

export const HeroText = styled.h1`
    font-size: calc(1.625rem + 1.54867vw);
    font-family: 'NuckleBold', sans-serif;
    font-weight: 'bold';
    color: ${colours.black};
    line-height: calc(1.625rem + 1.54867vw);
    @media (min-width: 1950px) {
        font-size: 56px;
    }
`;

export const CopyText = styled.p`
    font-family: 'Inter', sans-serif;
    font-weight: 'medium';
    color: ${colours.black};
    font-size: calc(0.6rem + 0.84867vw);
`;

export const SectionText = styled.div`
    color: ${colours.black};
    flex-direction: column;
    width: 50%;
    @media (max-width: ${dimensions.mobileMax}) {
        width: 100%;
        text-align: center;
    }
    span {
        color: ${colours.blue};
        font-weight: 700;
    }
`;

export const Disclaimer = styled.p`
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 11px;
    font-weight: 400;
`;
