import React from 'react';
import { ReactSVG } from 'react-svg';
import footerLinks from '../../../assets/data/footer.json';
import { NvContainerConstrained } from './containers.styles';
import {
    Divider,
    FooterBottomContainer,
    FooterContainer,
    FooterCopyrightCopy,
    FooterNav,
    FooterNavSection,
    FooterNavSectionHeader,
    FooterNavSectionLink,
    FooterSocial,
} from './footer.styles';
import InstagramSVG from '../../../assets/img/svgs/instagram-icon.svg';
import TwitterSVG from '../../../assets/img/svgs/twitter-icon.svg';
import YoutubeSVG from '../../../assets/img/svgs/youtube-icon.svg';

export const Footer = () => {
    return (
        <FooterContainer>
            <NvContainerConstrained>
                <FooterNav>
                    {footerLinks.map((footerGroup, i) => (
                        <FooterNavSection key={i}>
                            <FooterNavSectionHeader>{footerGroup.displayText}</FooterNavSectionHeader>
                            {footerGroup.subHeaders && (
                                <ul>
                                    {footerGroup.subHeaders.map((subHeader, i) => (
                                        <FooterNavSectionLink key={i}>
                                            <a href={subHeader.url}>{subHeader.displayText}</a>
                                        </FooterNavSectionLink>
                                    ))}
                                </ul>
                            )}
                        </FooterNavSection>
                    ))}
                </FooterNav>
                <Divider />
                <FooterBottomContainer>
                    <FooterCopyrightCopy>
                        © {new Date().getUTCFullYear()} New Vision. All rights reserved
                    </FooterCopyrightCopy>
                    <FooterSocial>
                        <a href="//www.instagram.com/newvision.uk">
                            <ReactSVG src={InstagramSVG} />
                        </a>
                        <a href="//www.youtube.com/channel/UCZei-B_NaSRBFcLgUxx8zMA">
                            <ReactSVG src={YoutubeSVG} />
                        </a>
                        <a href="//www.twitter.com/newvisionuk">
                            <ReactSVG src={TwitterSVG} />
                        </a>
                    </FooterSocial>
                </FooterBottomContainer>
            </NvContainerConstrained>
        </FooterContainer>
    );
};
