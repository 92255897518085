import React, { Fragment } from 'react';
import { MiddleAlign, NvContainerConstrained } from '../../components/core/layout/containers.styles';
import { PurpleStrip } from '../../components/core/layout/strips.styles';
import { CopyText, Disclaimer, HeroText } from '../../components/core/typography.styles';

import {
    ColumnFlex,
    DownloadButton,
    DownloadButtonContainer,
    DownloadHistoryBox,
    DownloadHistoryPadding,
    HistoryInformation,
    SmallerInformation,
    Thumbnail,
    TitleOfPack,
    TwoColumnFlex,
} from './account-area.styles';
import accountHistory from '../../assets/data/account-area-previous-orders.json';
import { Form, FlexDiv, FormEmailLabel, FormEmailInput } from '../../components/marketing/newsletter-panel.styles';
import { useAuth0 } from '@auth0/auth0-react';
import { LogOutBtn } from '../../components/core/button/account-buttons';

const AccountArea = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        <NvContainerConstrained>Loading</NvContainerConstrained>;
    }

    if (isAuthenticated) {
        return (
            <Fragment>
                <NvContainerConstrained>
                    <PurpleStrip>
                        <MiddleAlign>
                            <ColumnFlex>
                                <HeroText style={{ fontSize: '45px', margin: '20px 0 20px 0' }}>
                                    {user?.nickname}
                                </HeroText>
                                <CopyText>{user?.email}</CopyText>
                                <Disclaimer>{user?.sub}</Disclaimer>
                                <LogOutBtn />
                            </ColumnFlex>
                        </MiddleAlign>
                    </PurpleStrip>
                    <TwoColumnFlex>
                        <ColumnFlex>
                            <CopyText>Previous Orders</CopyText>
                            {accountHistory.map((order) => (
                                <DownloadHistoryBox key={order.title}>
                                    <DownloadHistoryPadding>
                                        <Thumbnail src={order.thumbnail} />
                                        <HistoryInformation>
                                            <TitleOfPack>{order.title}</TitleOfPack>
                                            <SmallerInformation>{order.purchaseDate}</SmallerInformation>
                                            <SmallerInformation>{`£${order.price}`}</SmallerInformation>
                                            <SmallerInformation>
                                                Downloads remaining <span>{order.remainingDownloads}</span>
                                            </SmallerInformation>
                                        </HistoryInformation>
                                    </DownloadHistoryPadding>
                                    <DownloadButtonContainer>
                                        {order.remainingDownloads > 0 && <DownloadButton>Download</DownloadButton>}
                                        <DownloadButton>Invoice</DownloadButton>
                                        <DownloadButton>Support</DownloadButton>
                                    </DownloadButtonContainer>
                                </DownloadHistoryBox>
                            ))}
                        </ColumnFlex>
                        <ColumnFlex>
                            <CopyText>Account Settings</CopyText>
                            <Form>
                                <FlexDiv>
                                    <FormEmailLabel>Email</FormEmailLabel>
                                    <FormEmailInput id="email" placeholder={'e.g example@google.com'}></FormEmailInput>
                                </FlexDiv>
                            </Form>
                        </ColumnFlex>
                    </TwoColumnFlex>
                    <CopyText>Auth0</CopyText>
                    <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                        {JSON.stringify(user, null, 2)}
                    </pre>
                </NvContainerConstrained>
            </Fragment>
        );
    }

    return <></>;
};

export default AccountArea;
