import styled from 'styled-components';
import dimensions from '../../components/core/config/dimensions.json';
import colours from '../../components/core/config/colours.json';

export const FullPageProductHeading = styled.div`
    width: 100%;
    display: inline-flex;
    @media (max-width: ${dimensions.mobileMax}) {
        display: flex;
        flex-direction: column;
    }
`;

export const FullPageProductPreview = styled.div`
    margin-right: 24px;
    @media (max-width: ${dimensions.mobileMax}) {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0 0 32px 0;
    }
`;

export const FullPageProductCopy = styled.div`
    display: inline-block;
    width: 50%;
    text-align: end;
    @media (max-width: ${dimensions.mobileMax}) {
        width: 100%;
        text-align: center;
        justify-content: center;
    }
`;

export const FullPageProductCopyTitle = styled.h1`
    font-size: 42px;
    color: ${colours.blue};
    font-weight: 800;
    margin: 0 0 8px 0;
`;

export const FullPageProductCopySub = styled.h1`
    margin: 0 0 8px 0;
    font-size: 24px;
    color: ${colours.white};
    font-weight: 400;
`;

export const StillCollageList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
`;

export const KeyFactsList = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const KeyFact = styled.div`
    flex: 1 10%;
    padding: 3%;
    @media (max-width: 500px) {
        flex: 1 30%;
    }
`;

export const Asset = styled.p`
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 700;
`;

export const AssetValue = styled.p`
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 400;
`;

export const Thumbnail = styled.li`
    flex: 1 25%;
    padding: 1%;
    list-style: none;
    transition: 0.15s ease;

    @media (max-width: ${dimensions.mobileMax}) {
        flex: 1 45%;
        padding-top: 0.5%;
        padding-bottom: 0.5%;
    }

    :first-child,
    :nth-child(1n) {
        @media (max-width: ${dimensions.mobileMax}) {
            padding-left: 0;
        }
    }

    :nth-child(3n) {
        @media (max-width: ${dimensions.mobileMax}) {
            padding-left: 0;
        }
    }

    :first-child,
    :nth-child(4n) {
        padding-left: 0;
    }

    :hover {
        border: 3px solid ${colours.black};
        box-shadow: 3px 3px 0px ${colours.black};
        padding: 0;
        margin: 0.25%;
    }
`;
