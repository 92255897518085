import emptyProduct from '../assets/data/empty-product.json';

export const getEmptyProductRestResponseType = (): ProductRestResponseType => {
    return emptyProduct;
};
export interface ProductRestResponseType {
    publicReference: string;
    skuId: string;
    title: string;
    tagline: string;
    loopLength: number;
    views: number;
    media: Media;
    links: Links;
    createdDate: number;
    category: string;
    keywordsList: string[];
    facts: Facts;
    files: File[];
    fileCount?: number;
    contentInPackList: ContentInPack;
    formatList: string[];
    qualityList: string[];
    relatedPacksList: CatalogueSearchRepresentation[];
    customisable: boolean;
    seamless: boolean;
    singular: boolean;
}

export interface CatalogueSearchRepresentation {
    title: string;
    category: string;
    fullPackUrl: string;
    thumbnail: string;
    price: string;
    keywords: string[];
    discounted?: string;
}

export interface ContentInPack {
    longThumbnail?: string;
    thumbnails: string[];
}

export interface Facts {
    heroFacts: Fact[];
    keyFacts: Fact[];
    compatibleProgram: string[];
}

export interface Fact {
    fact: string;
    display: string;
    value: string;
}

export interface File {
    quality: string;
    format: string;
    size: string;
    location: string;
    price: number;
}

export interface Links {
    fullPageItemUrl: string;
    twitterUrl: string;
    facebookUrl: string;
}

export interface Media {
    thumbnail: string;
    preview: string;
}
