import React, { FC } from 'react';
import { withLayout } from './components/core/layout/with-layout';
import { Route, Switch } from 'react-router-dom';

import HomePage from './pages/homepage/homepage';
import AboutUs from './pages/content-pages/about-us';
import TheTeam from './pages/content-pages/the-team';
import HelpLandingPage from './pages/content-pages/help';
import FaithStatement from './pages/content-pages/faith-statement';
import CancelledPayment from './pages/content-pages/cancelled-payment';
import GoodPayment from './pages/content-pages/good-payment';
import AccountArea from './pages/account/account-area';
import Search from './pages/search/search';
import FullPageProduct from './pages/full-page-product/full-page-product-container';
import ErrorPage from './pages/content-pages/error';
import LoggedOut from './pages/account/logged-out';
import Cart from './pages/purchase-journey/cart-page';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const Routes = () => {
    const HomePageWithLayout = withLayout(HomePage, {
        title: 'Home',
        metaDescription: 'New Vision Church Media Library',
    });

    const AboutUsWithLayout = withLayout(AboutUs, { title: 'About Us', metaDescription: '' });
    const TheTeamWithLayout = withLayout(TheTeam, { title: 'The Team', metaDescription: '' });
    const HelpWithLayout = withLayout(HelpLandingPage, { title: 'Help', metaDescription: '' });
    const FaithStatementWithLayout = withLayout(FaithStatement, { title: 'Faith', metaDescription: '' });

    const CancelPaymentWithLayout = withLayout(CancelledPayment, { title: 'Cancelled Payment', metaDescription: '' });
    const SuccessPaymentWithLayout = withLayout(GoodPayment, { title: 'Successful', metaDescription: '' });
    const CartWithLayout = withLayout(Cart, { title: 'Cart', metaDescription: '' });
    const AccountAreaWithLayout = withLayout(AccountArea, { title: 'My Account', metaDescription: '' });
    const LoggedOutWithLayout = withLayout(LoggedOut, { title: 'Login', metaDescription: '' });

    const SearchWithLayout = withLayout(Search, { title: 'Search', metaDescription: '' });
    const FullPageProductWithLayout = withLayout(FullPageProduct, { title: 'Product', metaDescription: '' });
    const ErrorWithLayout = withLayout(ErrorPage, { title: 'Errrroooorrrrrrr!!!!', metaDescription: '' });

    return (
        <Switch>
            <Route exact path="/" component={HomePageWithLayout} />

            <Route exact path="/about-us" component={AboutUsWithLayout} />
            <Route exact path="/the-team" component={TheTeamWithLayout} />
            <Route exact path="/help" component={HelpWithLayout} />
            <Route exact path="/faith-statement" component={FaithStatementWithLayout} />

            <Route exact path="/cancel-payment" component={CancelPaymentWithLayout} />
            <Route exact path="/cart" component={CartWithLayout} />
            <Route exact path="/success" component={SuccessPaymentWithLayout} />

            <Route path="/logged-out" component={LoggedOutWithLayout} />
            <ProtectedRoute path="/my-account" component={AccountAreaWithLayout} />

            <Route exact path="/search" component={SearchWithLayout} />
            <Route exact path="/product/:publicReference" component={FullPageProductWithLayout} />

            <Route component={ErrorWithLayout} />
        </Switch>
    );
};

const ProtectedRoute = (props: { component: FC; path: string }) => {
    return <Route path={props.path} component={withAuthenticationRequired(props.component)} />;
};

export default Routes;
