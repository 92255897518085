import React from 'react';
import styled from 'styled-components';
import colours from '../core/config/colours.json';
import dimensions from '../core/config/dimensions.json';

type KeywordPillProp = {
    keywords: string[];
};

type KeywordProp = {
    keyword: string;
};

const KeywordPillStyled = styled.div`
    background-color: ${colours.white};
    color: ${colours.black};
    border-radius: 2px;
    font-weight: 700;
    padding: 2px 4px;
    font-size: 10px;
    margin: 0 0 4px;
    margin-right: 8px;
    :last-child {
        margin-right: 0;
    }
`;

const KeywordGroupUL = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    list-style: none;
    @media (max-width: ${dimensions.mobileMax}) {
        justify-content: center;
    }
`;
const KeywordPill = (props: KeywordProp) => {
    return <KeywordPillStyled>{props.keyword}</KeywordPillStyled>;
};

const KeywordGroup = (props: KeywordPillProp) => {
    return (
        <KeywordGroupUL>
            {props.keywords.map((keyword) => (
                <KeywordPill keyword={keyword} key={keyword} />
            ))}
        </KeywordGroupUL>
    );
};

export default KeywordGroup;
