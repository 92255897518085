import styled from 'styled-components';
import dimensions from '../config/dimensions.json';
import colours from '../config/colours.json';
import fonts from '../config/fonts.json';

export const FooterContainer = styled.div`
    font-family: ${fonts.inter};
    width: 100%;
`;

export const FooterNav = styled.div`
    display: flex;
    padding-top: 32px;
    max-width: ${dimensions.maxWidth};
    color: ${colours.white};
    @media (max-width: 600px) {
        flex-direction: column;
        justify-content: start;
    }
`;

export const FooterNavSectionLink = styled.li`
    a {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 14px;
        color: ${colours.black};
        :hover {
            color: ${colours.blue};
        }
    }
`;

export const FooterNavSection = styled.section`
    flex: 25%;
    @media (max-width: 600px) {
        flex: 50%;
    }
`;

export const FooterNavSectionHeader = styled.h1`
    font-size: 24px;
    font-weight: 700;
    font-family: 'NuckleBold';
    @media (max-width: ${dimensions.mobileMax}) {
        display: flex;
    }
`;

export const Divider = styled.div`
    margin-top: 32px;
    border-top: 1px solid ${colours.black};
    @media (max-width: ${dimensions.mobileMax}) {
        width: 100%;
    }
`;

export const FooterBottomContainer = styled.div`
    padding-top: 16px;
    display: flex;
    @media (max-width: 500px) {
        flex-direction: column;
    }
`;

export const FooterCopyrightCopy = styled.span`
    width: 50%;
    color: ${colours.black};
    font-family: 'NuckleBold';
    @media (max-width: 500px) {
        width: 100%;
    }
`;

export const FooterSocial = styled.span`
    display: flex;
    justify-content: flex-end;
    width: 50%;
    color: ${colours.black};
    a {
        padding-left: 16px;
    }
    @media (max-width: 500px) {
        justify-content: start;
        a {
            padding: 0;
        }
        width: 100%;
    }
`;
