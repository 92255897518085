import React, { Fragment } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { useCart } from 'react-use-cart';
import DeleteIcon from '../../assets/img/svgs/delete.svg';
import { CartToCheckout } from '../../components/core/button/stripe-button';
import { HeaderLinkA } from '../../components/core/layout/header.styles';
import { MiddleAlign, NvContainerConstrained } from '../../components/core/layout/containers.styles';
import { BeigeStripeWide, MintStrip } from '../../components/core/layout/strips.styles';
import { Disclaimer, HeroText } from '../../components/core/typography.styles';
import colours from '../../components/core/config/colours.json';

const CartItem = styled.section`
    flex: 1 45%;
    display: flex;
    padding: 0 16px 0 16px;
    @media (max-width: 600px) {
        flex-wrap: wrap;
    }
`;

const CartStyle = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const CartItemImageWrapper = styled.div`
    flex: 1 65%;
    margin-right: 16px;
    max-width: 300px;
    @media (max-width: 600px) {
        margin-right: 0px;
    }
`;

const TotalPriceFloat = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-right: 16px;
`;

const CartItemImage = styled.img`
    object-fit: contain;
    max-width: 100%;
`;

const CartItemInfo = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

const DeleteIconFlexEnd = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    div {
        max-height: 20px;
        :hover {
            cursor: pointer;
            filter: invert(0.5) sepia(1) hue-rotate(200deg) saturate(4) brightness(1);
        }
    }
`;

const CartItemIncDelete = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
`;

const CopyText = styled.p`
    font-family: 'NuckleBold', sans-serif;
    font-weight: 'medium';
    color: ${colours.black};
    font-size: calc(0.5rem + 0.84867vw);
    @media (max-width: 600px) {
        font-size: calc(0.8rem + 0.84867vw);
    }
`;

const PriceText = styled.p`
    font-family: 'Inter', sans-serif;
    font-weight: 'light';
    color: ${colours.black};
    font-size: calc(0.5rem + 0.84867vw);
    @media (max-width: 600px) {
        font-size: calc(0.8rem + 0.84867vw);
    }
`;

const PriceDeleteContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 16px;
    justify-content: flex-end;
    text-align: end;
    @media (max-width: 600px) {
        text-align: center;
    }
`;

export const SecureCartDisclaimer = styled(Disclaimer)`
    text-align: end;
    opacity: 0.5;
    @media (max-width: 600px) {
        text-align: center;
    }
`;

const Cart = () => {
    const { items, removeItem, cartTotal } = useCart();

    return (
        <Fragment>
            <NvContainerConstrained>
                <MintStrip style={{ marginBottom: '16px' }}>
                    <MiddleAlign>
                        <HeroText>Items in your cart</HeroText>
                    </MiddleAlign>
                </MintStrip>
                <CartStyle>
                    {items.map((item, i) => (
                        <CartItem key={i} style={{ margin: '16px 0 0 0' }}>
                            <CartItemImageWrapper>
                                <CartItemImage src={item.thumbnail} />
                            </CartItemImageWrapper>
                            <CartItemIncDelete>
                                <CartItemInfo>
                                    <CopyText>{item.title}</CopyText>
                                    <PriceText>£{item.price}</PriceText>
                                </CartItemInfo>
                                <DeleteIconFlexEnd onClick={() => removeItem(item.id)}>
                                    <ReactSVG src={DeleteIcon} />
                                </DeleteIconFlexEnd>
                            </CartItemIncDelete>
                        </CartItem>
                    ))}
                    <BeigeStripeWide style={{ margin: '32px 0 16px 0' }}>
                        <TotalPriceFloat>
                            <div>
                                <CopyText>Total: £{cartTotal}</CopyText>
                            </div>
                        </TotalPriceFloat>
                    </BeigeStripeWide>
                </CartStyle>
                <PriceDeleteContainer>
                    <div style={{ width: '100%' }}>
                        <HeaderLinkA href="/search">Continue shopping</HeaderLinkA>
                        <CartToCheckout email={'charliewsay@gmail.com'} skuId={items.flatMap((item) => item.id)} />
                        <SecureCartDisclaimer>
                            <i>
                                Secure Checkout - you’ll be redirected towards <b>Stripe</b> our payment partner
                            </i>
                        </SecureCartDisclaimer>
                    </div>
                </PriceDeleteContainer>
            </NvContainerConstrained>
        </Fragment>
    );
};

export default Cart;
