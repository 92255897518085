import styled from 'styled-components';
import { BlueCTABtnAsBtn } from '../../components/core/button/buttons.styles';
import colours from '../../components/core/config/colours.json';
import { HeroText, SectionText } from '../../components/core/typography.styles';

export const TwoColumnFlex = styled.div`
    display: flex;
    @media (max-width: 864px) {
        flex-direction: column;
    }
`;

export const ColumnFlex = styled.div`
    flex: 1 48%;
    text-align: center;
    justify-content: center;
    :first-child {
        margin-right: 1.5%;
    }
    :nth-child(2n) {
        margin-left: 1.5%;
    }
    @media (max-width: 864px) {
        padding: 5%;
    }
`;

export const DownloadHistoryBox = styled.div`
    color: ${colours.black};
    background: ${colours.white};
    border: 3px solid ${colours.black};
    box-shadow: 3px 3px 0px ${colours.black};
    width: 100%;
    margin-bottom: 2%;
`;

export const DownloadButton = styled(BlueCTABtnAsBtn)`
    flex: 1 30%;
    border-radius: 0;
    margin: 0 1% 1% 1%;
`;

export const DownloadButtonContainer = styled.div`
    display: flex;
    justify-content: start;
`;

export const DownloadHistoryPadding = styled.div`
    padding: 1%;
    display: flex;
    @media (max-width: 600px) {
        flex-direction: column;
        justify-content: center;
    }
`;

export const HistoryInformation = styled.div`
    width: 100%;
    text-align: start;
    padding-left: 2%;
    @media (max-width: 600px) {
        padding-left: 0;
    }
`;

export const SmallerInformation = styled(SectionText)`
    text-align: start;
`;

export const TitleOfPack = styled(HeroText)`
    margin: 0;
    font-size: 22px;
    line-height: 43px;
`;

export const Thumbnail = styled.img`
    width: 185px;
    height: 104px;
    border-radius: 2px;
`;
