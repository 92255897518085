export const SearchSanitiser = (sanitisingString) => {
    return sanitisingString.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
};

export const SearchSplitter = (longString) => {
    if (longString.length === 0) {
        return [];
    }
    return longString[0].split(',');
};

export const twoDecimals = (number) => parseFloat(number).toFixed(2);
