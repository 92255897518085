import React, { useState } from 'react';
import { MiddleAlign, NvContainerConstrained } from '../core/layout/containers.styles';
import { CopyText, Disclaimer, HeroText } from '../core/typography.styles';
import {
    BeigeNewsletterStrip,
    FlexDiv,
    Form,
    FormEmailInput,
    FormEmailLabel,
    SubmitBlueCta,
} from './newsletter-panel.styles';

export const NewsletterPanel = () => {
    const [submitted, setIsSubmitted] = useState(false);
    const [validForm, setIsValidForm] = useState(false);

    const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        console.log('submitted');
        setIsSubmitted(true);
    };

    const emailIsValid = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsValidForm(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value));
    };

    return (
        <BeigeNewsletterStrip>
            {!submitted ? (
                <>
                    <MiddleAlign>
                        <HeroText style={{ fontSize: 'calc(1.5rem + 1vw)', textAlign: 'center', margin: 0 }}>
                            Send me the latest deals, discounts & offers
                        </HeroText>
                    </MiddleAlign>

                    <NvContainerConstrained>
                        <Form onChange={emailIsValid} onSubmit={handleSubmit}>
                            <FlexDiv>
                                <FormEmailLabel>Your Email</FormEmailLabel>
                                <FormEmailInput id="email" placeholder={'e.g example@google.com'} />
                            </FlexDiv>
                            <SubmitBlueCta style={{ height: '42px', top: '2px' }} disabled={!validForm}>
                                Submit
                            </SubmitBlueCta>
                        </Form>
                        <Disclaimer>
                            By signing up, you agree to receive newsletters in accordance with our privacy notice. You
                            can unsubscribe at any time.
                        </Disclaimer>
                    </NvContainerConstrained>
                </>
            ) : (
                <MiddleAlign>
                    <HeroText>Thanks for signing up!</HeroText>
                    <CopyText>We promise our newsletter is great.</CopyText>
                </MiddleAlign>
            )}
        </BeigeNewsletterStrip>
    );
};
