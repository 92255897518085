import styled from 'styled-components';
import { BlueCTABtnAsBtn } from '../core/button/buttons.styles';
import colours from '../core/config/colours.json';
import { BeigeStrip } from '../core/layout/strips.styles';

export const FormContainer = styled.div`
    width: 100%;
`;

export const BeigeNewsletterStrip = styled(BeigeStrip)`
    padding: 32px 0 8px 0;
`;

export const Disclaimer = styled.p`
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 11px;
    font-weight: 400;
`;

export const Form = styled.form`
    display: flex;
    margin: 0 auto;
    max-width: 408px;
    align-items: flex-end;
    flex-flow: row wrap;
    @media (max-width: 560px) {
        margin: auto;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
`;

export const FlexDiv = styled.div`
    flex: 1 1;
    margin-right: 8px;
    @media (max-width: 560px) {
        width: 100%;
    }
`;

export const SubmitBlueCta = styled(BlueCTABtnAsBtn)`
    @media (max-width: 560px) {
        width: 100%;
        margin-top: 8px;
    }
`;

export const FormEmailLabel = styled.label`
    color: ${colours.black};
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 11px;
    margin-bottom: 4px;
`;

export const FormEmailInput = styled.input`
    border-color: ${colours.whiteDarker};
    color: ${colours.black};
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    padding: 0 16px 0 12px;
    border: 1px solid ${colours.black};
    box-shadow: 3px 3px 0px ${colours.black};
    -webkit-appearance: none;
    -webkit-box-shadow: 3px 3px 0px ${colours.black};
    box-sizing: border-box;
    min-width: 220px;
    width: 100%;
    margin: 0 8px 0 0;
    height: 40px;
`;
