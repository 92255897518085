import React from 'react';
import { WhiteLayeredCTABtn } from '../../components/core/button/buttons.styles';
import { MiddleAlign, NvContainer, NvContainerConstrained } from '../../components/core/layout/containers.styles';
import { MintStripeWide } from '../../components/core/layout/strips.styles';
import { CopyText, HeroText } from '../../components/core/typography.styles';

const CancelledPayment = () => {
    return (
        <NvContainer>
            <MintStripeWide>
                <NvContainerConstrained>
                    <MiddleAlign>
                        <HeroText>Seems you cancelled your payment!</HeroText>
                    </MiddleAlign>
                    <MiddleAlign>
                        <CopyText>No bother, you can just view more content here!</CopyText>
                    </MiddleAlign>
                    <MiddleAlign>
                        <WhiteLayeredCTABtn href={'/search'}>Find content</WhiteLayeredCTABtn>
                    </MiddleAlign>
                </NvContainerConstrained>
            </MintStripeWide>
        </NvContainer>
    );
};

export default CancelledPayment;
