import React, { Fragment } from 'react';
import { BlueCTABtn, WhiteLayeredCTABtn } from '../../components/core/button/buttons.styles';
import { CenterAlign, ImageLeftHero, Section, SectionText } from './homepage.styles';
import { NvContainerConstrained, TopBottomContainedPadding } from '../../components/core/layout/containers.styles';
import { NewsletterPanel } from '../../components/marketing/newsletter-panel';
import SundayStarterPack from '../../assets/img/constant-images/sunday-starter-pack.gif';
import { MintStrip } from '../../components/core/layout/strips.styles';
import { CopyText, HeroText } from '../../components/core/typography.styles';
import { useAuth0 } from '@auth0/auth0-react';

const HomePage = () => {
    const search = '/search';
    const searchByNew = '/search?sort=new';
    const { user } = useAuth0();
    console.log(user);
    console.log(process.env.NODE_ENV);
    return (
        <Fragment>
            <MintStrip>
                <NvContainerConstrained>
                    <Section>
                        <CenterAlign>
                            <SectionText style={{ width: '100%' }}>
                                <HeroText>Video content for the local Church</HeroText>
                                <CopyText>
                                    New Vision has the aim to equip the UK Church with powerful visual aids to bolster
                                    your church services.
                                </CopyText>
                                <WhiteLayeredCTABtn href={search}>Find content</WhiteLayeredCTABtn>
                            </SectionText>
                        </CenterAlign>
                    </Section>
                </NvContainerConstrained>
            </MintStrip>
            <NvContainerConstrained>
                <TopBottomContainedPadding>
                    <Section>
                        <ImageLeftHero src={SundayStarterPack} />
                        <SectionText>
                            <HeroText>Sunday Starter Pack</HeroText>
                            <CopyText>
                                Invest in a wide variety of over 200 praise and worship backgrounds for your church.
                                Coming soon…
                            </CopyText>
                            <BlueCTABtn href={searchByNew}>Explore the new stuff</BlueCTABtn>
                        </SectionText>
                    </Section>
                </TopBottomContainedPadding>
            </NvContainerConstrained>
            <NvContainerConstrained>
                <NewsletterPanel />
            </NvContainerConstrained>
        </Fragment>
    );
};

export default HomePage;
