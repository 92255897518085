import styled from 'styled-components';
import colours from '../config/colours.json';

export const MintStrip = styled.div`
    background-color: ${colours.mint};
    padding: 16px 0 16px 0;
`;

export const PurpleStrip = styled.div`
    background-color: ${colours.purple};
    padding: 16px 0 16px 0;
    margin: 16px 0 16px 0;
`;

export const BeigeStrip = styled.div`
    background-color: ${colours.beige};
    padding: 16px 0 16px 0;
`;

export const BlueStrip = styled.div`
    background-color: ${colours.blue};
    padding: 16px 0 16px 0;
`;

export const BeigeStripeWide = styled(BeigeStrip)`
    width: 100%;
`;

export const PurpleStripeWide = styled(PurpleStrip)`
    width: 100%;
`;

export const MintStripeWide = styled(MintStrip)`
    width: 100%;
`;

export const BlueStripWide = styled(BlueStrip)`
    width: 100%;
`;
