import React, { useState } from 'react';
import filterList from '../../assets/data/search-filters.json';
import styled from 'styled-components';
import Select from 'react-select';
import { WhiteLayeredCTABtn } from '../core/button/buttons.styles';
const SearchFiltersDiv = styled.div`
    display: flex;
    padding-bottom: 8px;
    justify-content: space-between;
    flex: 1 100%;
    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

const FiltersContainer = styled.div`
    display: flex;
    flex: 1 100%;
    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

const DropdownContainer = styled.div`
    padding-right: 16px;
    flex: 1 100%;
    :hover {
        cursor: pointer;
    }

    @media (max-width: 600px) {
        padding-bottom: 12px;
        padding-right: 0px;
        width: 100%;
    }
`;

const constructParams = (params: string[]): string => {
    const truncatedArray = params.filter((entry) => {
        return entry.trim() != '';
    });
    return '/search?' + truncatedArray.toString().replaceAll(',', '&');
};

const constructPopularKeywords = (event: { value: string }[]): string => {
    let baseKeyword = 'keywords=';

    event.forEach((keyword) => (baseKeyword = baseKeyword + keyword.value + '+'));

    return baseKeyword.substring(0, baseKeyword.length - 1);
};

const SearchFilters = () => {
    const [searchFilters, setSearchFilters] = useState(['']);

    return (
        <SearchFiltersDiv>
            <FiltersContainer>
                {filterList.map((item) => (
                    <DropdownContainer key={item.label}>
                        <Select
                            options={item.children}
                            onChange={
                                item.isMulti
                                    ? (event: [{ value: string }]) => {
                                          setSearchFilters((searchFilters) => [
                                              ...searchFilters,
                                              constructPopularKeywords(event),
                                          ]);
                                      }
                                    : (event: { value: string }) =>
                                          setSearchFilters((searchFilters) => [...searchFilters, event.value])
                            }
                            closeMenuOnSelect={true}
                            placeholder={item.label}
                            isMulti={item.isMulti}
                            menuColor="red"
                        />
                    </DropdownContainer>
                ))}
            </FiltersContainer>
            <WhiteLayeredCTABtn href={constructParams(searchFilters)}>Search</WhiteLayeredCTABtn>
        </SearchFiltersDiv>
    );
};

export default SearchFilters;
