import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { BlueCTABtnAsBtn } from './buttons.styles';
import { APP_URL } from '../../../utils/urls';
interface LogInBtnProps {
    fullWidth?: boolean;
}

export const LogInBtn = (props: LogInBtnProps) => {
    const { loginWithRedirect } = useAuth0();

    const redirectTo = APP_URL + '/my-account';

    return (
        <BlueCTABtnAsBtn
            style={{ width: props.fullWidth ? '100%' : '' }}
            onClick={() => loginWithRedirect({ redirectUri: redirectTo })}
        >
            Log in
        </BlueCTABtnAsBtn>
    );
};

export const LogOutBtn = (props: LogInBtnProps) => {
    const { logout } = useAuth0();

    const redirectTo = APP_URL + '/logged-out';

    return (
        <BlueCTABtnAsBtn
            style={{ width: props.fullWidth ? '100%' : '' }}
            onClick={() => logout({ returnTo: redirectTo })}
        >
            Sign out
        </BlueCTABtnAsBtn>
    );
};
