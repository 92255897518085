import styled from 'styled-components';
import dimensions from '../../components/core/config/dimensions.json';
import colours from '../../components/core/config/colours.json';
import stroke from '../../assets/img/constant-images/stroke.png';

export const ImageRightHero = styled.img`
    justify-content: end;
    text-align: end;
    width: 30%;
    padding-top: 16px;
    @media (max-width: ${dimensions.mobileMax}) {
        flex-direction: column;
        width: 100%;
        max-width: 290px;
    }
`;

export const Highlight = styled.span`
    background: url(${stroke});
    background-repeat: no-repeat;
    background-size: 100% 95%;
    padding: 8px 0;
`;

export const ImageLeftHero = styled.img`
    justify-content: start;
    text-align: start;
    width: 45%;
    padding-top: 16px;
    @media (max-width: ${dimensions.mobileMax}) {
        flex-direction: column;
        width: 100%;
    }
`;

export const CenterAlign = styled.div`
    text-align: center;
    justify-content: center;
`;

export const Section = styled.div`
    max-width: ${dimensions.maxWidth};
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: ${dimensions.mobileMax}) {
        flex-direction: column;
    }
`;

export const SectionText = styled.div`
    color: ${colours.black};
    flex-direction: column;
    width: 50%;
    @media (max-width: ${dimensions.mobileMax}) {
        width: 100%;
        text-align: center;
    }
`;
