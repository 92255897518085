import React, { useEffect, useState } from 'react';
import { WhiteLayeredCTABtn } from '../../components/core/button/buttons.styles';
import { MiddleAlign, NvContainer, NvContainerConstrained } from '../../components/core/layout/containers.styles';
import { BeigeStripeWide } from '../../components/core/layout/strips.styles';
import { CopyText, Disclaimer, HeroText } from '../../components/core/typography.styles';
import Confetti from 'react-confetti';

const GoodPayment = () => {
    const [showConfetti, setShowConfetti] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setShowConfetti(false);
        }, 4500);
    });

    return (
        <NvContainer>
            <BeigeStripeWide>
                {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} numberOfPieces={50} />}
                <NvContainerConstrained>
                    <MiddleAlign>
                        <HeroText>Woo hoo - check your emails!</HeroText>
                    </MiddleAlign>
                    <MiddleAlign>
                        <CopyText>Content is on its way to you right now!</CopyText>
                    </MiddleAlign>
                    <MiddleAlign>
                        <Disclaimer>
                            It may take a few minutes to arrive, if it doesnt arrive within an hour. Please let us know.
                        </Disclaimer>
                    </MiddleAlign>
                    <MiddleAlign>
                        <WhiteLayeredCTABtn href={'/search'}>Find content</WhiteLayeredCTABtn>
                    </MiddleAlign>
                </NvContainerConstrained>
            </BeigeStripeWide>
        </NvContainer>
    );
};

export default GoodPayment;
