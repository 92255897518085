import styled, { keyframes } from 'styled-components';
import { BlueCTABtn } from '../button/buttons.styles';
import colours from '../config/colours.json';
import dimensions from '../config/dimensions.json';
export const FloatLeft = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const Logo = styled.a`
    font-family: 'NuckleBold', sans-serif;
    font-weight: 'bold';
    font-size: 24px;
    margin: 0;
    color: ${colours.black};
    transition: 0.45s;
    padding-top: 20px;
    :hover {
        color: ${colours.blue};
    }
`;

export const HeaderContainer = styled.div`
    height: 60px;
    display: flex !important;
    position: relative !important;
    margin: 0 auto !important;
    padding-left: 80px !important;
    padding-right: 80px !important;
    z-index: 1 !important;
    justify-content: space-between;
    max-width: ${dimensions.maxWidth};
`;

export const DropdownAccount = styled.div`
    display: none;
    transition: 0.2s;
`;

export const AccountAreaBtn = styled(BlueCTABtn)`
    :hover ${DropdownAccount} {
        display: block;
        background-color: white;
        box-shadow: 0 1px 3px #aaa;
        top: 40px;
        padding: 8% 5% 8% 5%;
        left: -40px;
        overflow: hidden;
        position: absolute;
        min-width: 120px;
        width: 100%;
        z-index: 2;
        border-radius: 2px;
        border-left-style: solid;
        border-color: ${colours.blue};

        a {
            color: ${colours.black};

            :hover {
                color: ${colours.blue};
            }
        }
    }
`;

export const MobileHeaderContainer = styled.div`
    width: 100%;
    display: inline-flex;
    position: relative !important;
    padding: 10px 0 10px 0px;
    z-index: 1 !important;
    justify-content: space-between;
`;

export const ULLI = styled.li``;

export const HeaderLinkA = styled.a`
    color: ${colours.black};
    margin: 0 16px 0 16px;
    transition: 0.1s;
    font-family: 'NuckleBold';
    :hover {
        color: ${colours.blue};
    }
`;

export const HeaderLinkAMobile = styled(HeaderLinkA)`
    color: ${colours.blue};
    font-size: 24px;
`;

export const MobileNavSection = styled.li`
    margin-bottom: 16px;
`;

export const SubLinkMobile = styled.li`
    margin-bottom: 8px;
`;

export const SubHeaderLinkA = styled.a`
    color: ${colours.blue};
    margin: 0 16px 0 16px;
    transition: 0.1s;
    font-size: 12px;
`;
export const SubHeaderLinkAMobile = styled(SubHeaderLinkA)`
    color: ${colours.black};
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 19px;
`;

export const UL = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
`;

export const MobileUL = styled.ul`
    display: flex;
    text-align: center;
    list-style-type: none;
    flex-direction: column;
    justify-content: center;
`;

export const Header = styled.header`
    background-color: ${colours.white};
    align-items: center;
    height: 100%;
`;

const slideInFromLeft = keyframes`
    from{
        transform: translateX(-100rem);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        opacity: 1;
    }
`;

export const MobileNavList = styled.nav`
    overflow-x: hidden;
    animation: ${slideInFromLeft} 0.55s forwards ease-in-out;
`;

export const HamburgerMenuPadding = styled.div`
    padding-right: 10px;
    display: flex;
`;

export const CartMenu = styled.a`
    display: flex;
    margin-right: 8px;
    @media (max-width: ${dimensions.mobileMax}) {
        margin-right: 24px;
    }
`;

export const Cart = styled.img`
    max-width: 24px;
    max-height: 24px;
`;
