import React, { Fragment, useState } from 'react';
import YouTube from '@u-wave/react-youtube';
import { Helmet } from 'react-helmet';
import camelCase from 'camelcase';
import { useCart } from 'react-use-cart';
import { ProductRestResponseType } from '../../service/product-services';
import StripeButton from '../../components/core/button/stripe-button';
import KeywordPillGroup from '../../components/keyword-pill/keyword-pill';
import {
    Asset,
    AssetValue,
    FullPageProductCopy,
    FullPageProductHeading,
    FullPageProductPreview,
    KeyFact,
    KeyFactsList,
    StillCollageList,
    Thumbnail,
} from './full-page.styles';

import { MiddleAlign, NvContainerConstrained } from '../../components/core/layout/containers.styles';
import { BeigeStripeWide, MintStripeWide } from '../../components/core/layout/strips.styles';
import { NewsletterPanel } from '../../components/marketing/newsletter-panel';
import ProductCatalogueCard from '../../components/product-card/product-catalogue-card';
import { CopyText, HeroText } from '../../components/core/typography.styles';
import { SearchResultGrid } from '../../components/search/search-results';
import { useAuth0 } from '@auth0/auth0-react';
import { WhiteCTABtnAsBtn } from '../../components/core/button/buttons.styles';

const FullPageProductPattern = (props: ProductRestResponseType) => {
    const { skuId, title, tagline, media, files, contentInPackList, keywordsList, facts, relatedPacksList } = props;
    const [addToCart, setAddToCart] = useState({ text: 'Add To Cart' });
    const price = (files && files[0] && files[0].price) || 0 || 0;
    const width = window.innerWidth < 860 ? window.innerWidth / 1 : window.innerWidth / 3;
    const height = window.innerWidth < 860 ? window.innerWidth / 1.9 : window.innerWidth / 5.4;
    const showContentInPackList = contentInPackList.thumbnails && contentInPackList.thumbnails.length > 0;
    const email = useAuth0().user?.email;

    const addItem =
        useCart().getItem(skuId) === undefined
            ? useCart().addItem({
                  id: skuId,
                  price: price,
                  quantity: 1,
                  title: title,
                  thumbnail: media.thumbnail,
              })
            : {};

    return (
        <Fragment>
            <Helmet>
                <title>{`${title} | New Vision`}</title>
            </Helmet>
            <BeigeStripeWide>
                <NvContainerConstrained style={{ margin: '24px auto 24px' }}>
                    <FullPageProductHeading>
                        <FullPageProductPreview>
                            <YouTube
                                video={media.preview}
                                width={width}
                                height={height}
                                showRelatedVideos={false}
                                showInfo={false}
                                modestBranding={true}
                            />
                        </FullPageProductPreview>
                        <FullPageProductCopy>
                            <KeywordPillGroup keywords={keywordsList.map((keyword) => keyword.toUpperCase())} />
                            <HeroText>{title}</HeroText>
                            <CopyText>{tagline}</CopyText>
                            <StripeButton price={price} skuId={skuId} email={email} />
                            <WhiteCTABtnAsBtn
                                onClick={() => {
                                    setAddToCart({ text: 'Added To Cart' }), addItem;
                                }}
                                style={{ marginLeft: '16px' }}
                            >
                                {addToCart.text}
                            </WhiteCTABtnAsBtn>
                        </FullPageProductCopy>
                    </FullPageProductHeading>
                </NvContainerConstrained>
            </BeigeStripeWide>
            {showContentInPackList && (
                <NvContainerConstrained style={{ margin: '24px auto 24px' }}>
                    <MiddleAlign>
                        <HeroText style={{ fontSize: 'calc(1rem + 1.24867vw)' }}>Whats in the pack?</HeroText>
                    </MiddleAlign>
                    <StillCollageList>
                        {contentInPackList.thumbnails &&
                            contentInPackList.thumbnails.map((thumbnail, i) => (
                                <Thumbnail key={`${i}-thumbnail`}>
                                    <img style={{ maxWidth: '100%' }} src={thumbnail} />
                                </Thumbnail>
                            ))}
                    </StillCollageList>
                </NvContainerConstrained>
            )}
            <NvContainerConstrained>
                <MintStripeWide style={{ marginTop: showContentInPackList ? '0' : '16px' }}>
                    <MiddleAlign>
                        <HeroText style={{ textAlign: 'center', fontSize: 'calc(1rem + 1.24867vw)' }}>
                            Heres some key facts about this pack
                        </HeroText>
                    </MiddleAlign>
                </MintStripeWide>
                <KeyFactsList>
                    {facts.keyFacts.map((fact) => (
                        <KeyFact key={fact.fact}>
                            <Asset>{fact.display} </Asset>
                            <AssetValue>{fact.value}</AssetValue>
                        </KeyFact>
                    ))}
                    <KeyFact key="compatibles">
                        <Asset>Compatible Programs</Asset>
                        {facts.compatibleProgram.map((compatibles) => (
                            <AssetValue key={compatibles}>{camelCase(compatibles, { pascalCase: true })}</AssetValue>
                        ))}
                    </KeyFact>
                </KeyFactsList>
            </NvContainerConstrained>
            {relatedPacksList.length > 0 && (
                <NvContainerConstrained style={{ margin: '24px auto 24px' }}>
                    <BeigeStripeWide>
                        <MiddleAlign>
                            <HeroText style={{ textAlign: 'center', fontSize: 'calc(1rem + 1.24867vw)' }}>
                                Similar content you may like
                            </HeroText>
                        </MiddleAlign>
                    </BeigeStripeWide>
                    <SearchResultGrid>
                        {relatedPacksList.map((relatedPack) => (
                            <ProductCatalogueCard {...relatedPack} key={relatedPack.fullPackUrl} />
                        ))}
                    </SearchResultGrid>
                </NvContainerConstrained>
            )}
            <NvContainerConstrained>
                <NewsletterPanel />
            </NvContainerConstrained>
        </Fragment>
    );
};

export default FullPageProductPattern;
