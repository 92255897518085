import React from 'react';
import { MiddleAlign, NvContainer, NvContainerConstrained } from '../../components/core/layout/containers.styles';
import { MintStripeWide } from '../../components/core/layout/strips.styles';
import { CopyText, HeroText } from '../../components/core/typography.styles';
import AboutUsHero from '../../assets/img/constant-images/about-us-hero.jpeg';
import styled from 'styled-components';
import dimensions from '../../components/core/config/dimensions.json';

const AboutUsGrid = styled.div`
    margin-top: 32px;
    display: flex;
    @media (max-width: ${dimensions.mobileMax}) {
        flex-direction: column;
    }
`;

const AboutUsHeroImage = styled.img`
    width: 40%;
    object-fit: cover;
    border-radius: 18px;
    @media (max-width: ${dimensions.mobileMax}) {
        width: 100%;
    }
`;

const AboutUsCopyText = styled(CopyText)`
    font-size: calc(0.3rem + 0.84867vw);
    @media (max-width: ${dimensions.mobileMax}) {
        font-size: calc(1rem + 0.84867vw);
    }
`;

const LeftPad = styled.div`
    padding-left: 32px;
    @media (max-width: ${dimensions.mobileMax}) {
        padding: 8px;
    }
`;

const About = () => {
    return (
        <NvContainer>
            <MintStripeWide>
                <MiddleAlign>
                    <HeroText>About Us</HeroText>
                </MiddleAlign>
            </MintStripeWide>
            <NvContainerConstrained>
                <AboutUsGrid>
                    <AboutUsHeroImage src={AboutUsHero} />
                    <LeftPad>
                        <AboutUsCopyText>
                            Our aim is to equip the local church with powerful visuals to bring your services to life.
                            The New Vision team has over 10 years experience creating high quality video content for
                            churches all over the world. We have had the pleasure of working with so many incredible
                            churches and brands including Hillsong, Compassion, Big Church Day Out and !Audacious
                            church.
                        </AboutUsCopyText>
                        <AboutUsCopyText>
                            We make high quality worship visuals at an affordable price. Our Sunday starter pack has
                            over 250 original loops for just £199. You can also purchase individual packs starting as
                            little a £6. We update our content library regularly, with two new packs every month. Which
                            means we stay fresh and up to date with the modern church.
                        </AboutUsCopyText>
                    </LeftPad>
                </AboutUsGrid>
                <AboutUsCopyText>
                    Find content for songs your church is already singing. Use the search by artist feature to find
                    content for you favourite worship songs: Hillsong UNITED, Hillsong Y&F, Elevation Worship, Bethel,
                    Planet Shakers, !Audacious Worship and more.
                </AboutUsCopyText>
            </NvContainerConstrained>
        </NvContainer>
    );
};

export default About;
