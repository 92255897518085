import React, { Component } from 'react';
import { useParams } from 'react-router-dom';
import FullPageProductPattern from './full-page-product-pattern';
import multipleProductFull from '../../assets/data/multiple-dummy-product.json';
import { ProductRestResponseType, getEmptyProductRestResponseType } from '../../service/product-services';
import { Helmet } from 'react-helmet';

const FullPageProductContainer = (product: ProductRestResponseType) => {
    return (
        <div>
            <FullPageProductPattern {...product} />
        </div>
    );
};

class FullPageProduct extends Component<{ publicReference: string }, FullPageProductState> {
    constructor(props: Readonly<{ publicReference: string }>) {
        super(props);
        this.state = {
            productData: getEmptyProductRestResponseType(),
            loading: false,
        };
    }

    async componentDidMount() {
        const endpoint = '';
        const proxyurl = '';
        const constructedUrl = proxyurl + endpoint + this.props.publicReference;

        fetch(constructedUrl, {
            headers: {
                category: 'pack',
            },
        })
            .then((res) => res.json())
            .then((data: ProductRestResponseType) => {
                this.setState({ productData: data, loading: false });
            })
            .catch(console.log);
    }

    render() {
        const isLoading = this.state.loading;

        const productFull = Math.random() > 0.5 ? multipleProductFull[0] : multipleProductFull[1];

        return (
            <div>
                <Helmet>
                    <script src="https://js.stripe.com/v3" type="text/javascript"></script>
                </Helmet>
                {isLoading && (
                    <div>
                        <FullPageProductContainer {...getEmptyProductRestResponseType()} />
                    </div>
                )}
                {isLoading === false && <FullPageProductContainer {...productFull} />}
            </div>
        );
    }
}

const FullPageProductForLayout = () => {
    const { publicReference } = useParams();

    return <FullPageProduct publicReference={publicReference} />;
};

type FullPageProductState = {
    productData: ProductRestResponseType;
    loading: boolean;
};

export default FullPageProductForLayout;
