import React, { FC, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

interface SpaEnvironmentConfig {
    [key: string]: string | number | boolean;
}

export interface AppConfig extends SpaEnvironmentConfig {
    appName: string;
    env: Environment;
}

enum Environment {
    local = 'local',
    preprod = 'preprod',
    prod = 'prod',
}

export interface AppContext {
    config: AppConfig;
    isDesktop: () => boolean;
    isMobile: () => boolean;
    isNarrowMobile: () => boolean;
}

const loadDefault = (): AppContext => {
    return {
        config: {
            appName: 'jonah',
            env: Environment.local,
        },
        isDesktop: () => true,
        isMobile: () => false,
        isNarrowMobile: () => false,
    };
};

const AppContext = React.createContext<AppContext>(({} as unknown) as AppContext);

// eslint-disable-next-line @typescript-eslint/ban-types
export const withAppContext = <P extends object>(
    WrappedComponent: FC<P>,
    defaultAppConfig: SpaEnvironmentConfig,
): FC<P> => {
    const defaultState = loadDefault();
    const mergedConfig = { ...defaultState.config, ...defaultAppConfig };
    const value = { ...defaultState, config: mergedConfig };

    const WithAppContext = (props: P) => {
        const isTabletOrMobile = useMediaQuery({ query: '(max-width: 860px)' });
        const isTabletOrMobileDevice = useMediaQuery({
            query: '(max-device-width: 860px)',
        });
        const isDesktop = useMediaQuery({
            query: '(min-device-width: 861px)',
        });
        const isMobile = isTabletOrMobile && isTabletOrMobileDevice;

        const isNarrowMobile = useMediaQuery({
            query: '(max-device-width: 420px)',
        });

        value.isDesktop = () => {
            return isDesktop;
        };

        value.isMobile = () => {
            return isMobile;
        };

        value.isNarrowMobile = () => {
            return isNarrowMobile;
        };
        return (
            <AppContext.Provider value={value}>
                <WrappedComponent {...props} />
            </AppContext.Provider>
        );
    };
    return WithAppContext;
};

export const useAppContext = () => useContext(AppContext);

export { loadDefault as defaultAppContext };
