import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PK, APP_URL } from '../../../utils/urls';
import { WhiteLayeredCTABtn } from './buttons.styles';
const stripePromise = loadStripe(STRIPE_PK);

type ButtonProps = {
    price: number;
    skuId: string;
    email?: string;
};

type CheckoutProps = {
    skuId: string[];
    email: string;
};

type StripeButtonState = {
    stripeButtonText: string;
};

class StripeButton extends React.Component<ButtonProps, StripeButtonState> {
    handleClick = async () => {
        const stripe = await stripePromise;
        const email = this.props.email ? { customerEmail: this.props.email } : {};
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const result = await stripe!.redirectToCheckout({
            lineItems: [{ price: this.props.skuId, quantity: 1 }],
            mode: 'payment',
            successUrl: `${APP_URL}/success`,
            cancelUrl: `${APP_URL}/cancel-payment`,
            ...email,
        });
        if (result.error) {
            console.log('error : ' + result.error.message);
        }
    };

    render() {
        return (
            <>
                <WhiteLayeredCTABtn onClick={this.handleClick}>Buy now for £{this.props.price}</WhiteLayeredCTABtn>
            </>
        );
    }
}

export class CartToCheckout extends React.Component<CheckoutProps, StripeButtonState> {
    constructor(props: CheckoutProps) {
        super(props);
        this.state = { stripeButtonText: 'Checkout Cart' };
    }

    handleClick = async () => {
        this.setState({ stripeButtonText: 'Redirecting...' });
        const stripe = await stripePromise;
        const email = this.props.email ? { customerEmail: this.props.email } : {};
        const itemsArray: { price: string; quantity: number }[] = [];
        this.props.skuId.forEach((element) => {
            itemsArray.push({ price: element, quantity: 1 });
        });
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const result = await stripe!.redirectToCheckout({
            lineItems: itemsArray,
            mode: 'payment',
            successUrl: `${APP_URL}/success`,
            cancelUrl: `${APP_URL}/cancel-payment`,
            ...email,
        });
        if (result.error) {
            console.log('error : ' + result.error.message);
        }
    };
    render() {
        return (
            <>
                <WhiteLayeredCTABtn onClick={this.handleClick}>{this.state.stripeButtonText}</WhiteLayeredCTABtn>
            </>
        );
    }
}

export default StripeButton;
