import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { CartProvider } from 'react-use-cart';

import App from './App';
import GlobalFonts from './assets/fonts/fonts';
import './base.css';

ReactDOM.render(
    <>
        <GlobalFonts />
        <BrowserRouter>
            <CartProvider>
                <Auth0Provider
                    domain="dev-7kjbp9mp.eu.auth0.com"
                    clientId="LG7OnFJ1Upss8rl1XHADvS9KPOskb0g0"
                    redirectUri={window.location.origin}
                    responseType={'token id_token'}
                    useRefreshTokens={true}
                    cacheLocation="localstorage"
                >
                    <App />
                </Auth0Provider>
            </CartProvider>
        </BrowserRouter>
    </>,
    document.getElementById('root'),
);
