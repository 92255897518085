import React from 'react';
import ProductCatalogueCard from '../product-card/product-catalogue-card';
import styled from 'styled-components';
import { CatalogueSearchRepresentation } from '../../service/product-services';

const SearchResultFlexContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SearchResultGrid = styled.section`
    display: flex;
    flex-wrap: wrap;
`;

type ResultProps = {
    previewList: CatalogueSearchRepresentation[];
};

const SearchResult = (props: ResultProps) => {
    const { previewList } = props;

    // const previewList: SearchResultPropTypes[] = [];

    return (
        <SearchResultFlexContainer>
            {previewList && (
                <SearchResultGrid>
                    {previewList.map((item, i) => (
                        <ProductCatalogueCard
                            title={item.title}
                            thumbnail={item.thumbnail}
                            fullPackUrl={item.fullPackUrl}
                            category={item.category}
                            price={item.price}
                            keywords={item.keywords}
                            discounted={item.discounted}
                            key={i}
                        />
                    ))}
                </SearchResultGrid>
            )}
            {previewList.length === 0 && (
                <div className="search-results--none">
                    <h2>
                        <span>Oops!</span> There seems to be no results here, try resetting your filters
                    </h2>
                    <p>These may be of interest to you</p>
                </div>
            )}
        </SearchResultFlexContainer>
    );
};

export default SearchResult;
