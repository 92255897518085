import React from 'react';
import { MiddleAlign, NvContainer, NvContainerConstrained } from '../../components/core/layout/containers.styles';
import { BeigeStripeWide } from '../../components/core/layout/strips.styles';
import { ImageRightHero, Section, SectionText } from '../homepage/homepage.styles';
import { CopyText, HeroText } from '../../components/core/typography.styles';
import OliP from '../../assets/img/constant-images/oli-pp.png';
import CharlieP from '../../assets/img/constant-images/charlie-pp.png';
import HarryP from '../../assets/img/constant-images/harry-pp.png';
import dimensions from '../../components/core/config/dimensions.json';
import styled from 'styled-components';

const TeamSection = styled(Section)`
    margin: 0;
    @media (max-width: ${dimensions.mobileMax}) {
        flex-direction: column;
    }
`;

const TeamSectionReversed = styled(TeamSection)`
    @media (max-width: ${dimensions.mobileMax}) {
        flex-direction: column-reverse;
    }
`;

const TheTeamPage = () => {
    return (
        <NvContainer>
            <BeigeStripeWide>
                <MiddleAlign>
                    <HeroText>The Team</HeroText>
                </MiddleAlign>
            </BeigeStripeWide>
            <NvContainerConstrained>
                <TeamSectionReversed>
                    <SectionText>
                        <HeroText>Oliver Wolstencroft</HeroText>
                        <CopyText>Founder and makes videos or something idk</CopyText>
                    </SectionText>
                    <ImageRightHero src={OliP} />
                </TeamSectionReversed>
            </NvContainerConstrained>
            <BeigeStripeWide>
                <NvContainerConstrained>
                    <TeamSection>
                        <ImageRightHero src={CharlieP} />
                        <SectionText>
                            <HeroText>Charlie Say</HeroText>
                            <CopyText>Does websites and like the backend and whatever tbh</CopyText>
                        </SectionText>
                    </TeamSection>
                </NvContainerConstrained>
            </BeigeStripeWide>
            <NvContainerConstrained>
                <TeamSectionReversed>
                    <SectionText>
                        <HeroText>Harry Raison</HeroText>
                        <CopyText>Consultant for New Vision Media</CopyText>
                    </SectionText>
                    <ImageRightHero src={HarryP} />
                </TeamSectionReversed>
            </NvContainerConstrained>
        </NvContainer>
    );
};

export default TheTeamPage;
