import React from 'react';
import { BlueStripWide } from './strips.styles';
import { MiddleAlign } from './containers.styles';

const Discount = () => {
    const { percentage, code } = { percentage: '15', code: 'HELLO2021' };
    return (
        <BlueStripWide style={{ padding: '8px 0 8px 0' }}>
            <MiddleAlign>
                <p
                    style={{
                        margin: 0,
                        fontSize: '10px',
                        color: 'white',
                        fontFamily: 'Nuckle',
                        fontWeight: 600,
                    }}
                >
                    Get {percentage}% off with promo code :{' '}
                    <span style={{ fontWeight: 800 }}>{code.toUpperCase()}</span> | THIS WEBSITE IS STILL IN ACTIVE
                    DEVELOPMENT. THERE MAY BE BROKEN LINKS & FUNCTIONALITY
                </p>
            </MiddleAlign>
        </BlueStripWide>
    );
};

export default Discount;
