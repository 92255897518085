import styled from 'styled-components';
import colours from '../config/colours.json';

const ButtonBaseA = styled.a`
    font-size: 14px;
    font-weight: 600;
    font-family: 'Inter', Helvetica Neue, helvetica, arial, sans-serif;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    width: auto;
    padding: 11px 18px 10px;
    height: 40px;
    vertical-align: middle;
    text-align: center;
`;

export const BlueCTABtn = styled(ButtonBaseA)`
    color: ${colours.white};
    background: ${colours.blue};
    border-radius: 2px;
    :hover {
        background: ${colours.hoverBlue};
        color: ${colours.white};
    }
`;

export const BtnAsBtnBase = styled.button`
    font-size: 14px;
    font-weight: 600;
    font-family: 'Inter', Helvetica Neue, helvetica, arial, sans-serif;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    width: auto;
    padding: 11px 18px 10px;
    height: 40px;
    vertical-align: middle;
    text-align: center;
`;

export const BlueCTABtnAsBtn = styled.button`
    font-size: 14px;
    font-weight: 600;
    border-width: 0;
    font-family: 'Inter', Helvetica Neue, helvetica, arial, sans-serif;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    width: auto;
    padding: 11px 18px 10px;
    height: 40px;
    vertical-align: middle;
    text-align: center;
    color: ${colours.white};
    background: ${colours.blue};
    border-radius: 2px;
    :hover {
        background: ${colours.hoverBlue};
        color: ${colours.white};
    }
    :disabled {
        background: ${colours.disabledBlue};
        color: ${colours.whiteDarker};
        cursor: not-allowed;
    }
`;

export const WhiteCTABtnAsBtn = styled(BtnAsBtnBase)`
    color: ${colours.black};
    background: ${colours.white};
    border: 3px solid ${colours.black};
    box-shadow: 3px 3px 0px ${colours.black};
    padding-top: 9px;
    transition: 0.15s ease;
    :hover {
        ::not([disabled]) {
            color: ${colours.black};
            box-shadow: 4px 4px 0px ${colours.black};
            padding-top: 8px;
        }
    }

    :disabled {
        opacity: 0.65;
        cursor: not-allowed;
    }
`;

export const WhiteLayeredCTABtn = styled(ButtonBaseA)`
    color: ${colours.black};
    background: ${colours.white};
    border: 3px solid ${colours.black};
    box-shadow: 3px 3px 0px ${colours.black};
    padding-top: 9px;
    transition: 0.15s ease;
    :hover {
        color: ${colours.black};
        box-shadow: 4px 4px 0px ${colours.black};
        padding-top: 8px;
    }
`;
